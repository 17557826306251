import {
  Boolean,
  Dictionary,
  Literal,
  Null,
  Optional,
  Record as RRecord,
  String as RString,
  Static,
  Union,
} from "runtypes";

import { notEmpty } from "./notEmpty.js";
import { StripePriceId } from "./stripeIdTypeBrands.js";
import { KernelImage } from "./typeBrands";
import { typedObjectEntries, typedObjectKeys } from "./utils/typedObjects";

export const DEFAULT_KERNEL_SIZE = "medium";

export const StandardKernelOptionsUnionLiteral = Union(
  Literal("xsmall"),
  Literal("small"),
  Literal("medium"),
  Literal("large"),
  Literal("xlarge"),
);

export const AdvancedComputeKernelOptionsUnionLiteral = Union(
  Literal("2xlarge"),
  Literal("4xlarge"),
  Literal("v100"),
  Literal("v100x8"),
);

const KernelOptionsUnionLiteral = Union(
  StandardKernelOptionsUnionLiteral,
  AdvancedComputeKernelOptionsUnionLiteral,
);

export const KernelSize = KernelOptionsUnionLiteral;
export type KernelSize = Static<typeof KernelOptionsUnionLiteral>;

export const KernelResourceConfig = RRecord({
  cpu: RString,
  memory: RString,
  "nvidia.com/gpu": Optional(RString),
});
export type KernelResourceConfig = Static<typeof KernelResourceConfig>;

const KernelSizeConfig = RRecord({
  humanName: RString,
  computePool: Optional(RString.nullable()),
  resources: RRecord({
    limits: KernelResourceConfig,
    requests: KernelResourceConfig,
  }),
  nodeGroup: Optional(RString.nullable()),
});

const KernelImageReplicas = Dictionary(Null, KernelSize);
type KernelImageReplicas = Static<typeof KernelImageReplicas>;

const KernelImageConfig = RRecord({
  name: RString,
  imageUrl: Optional(RString.nullable()),
  replicas: KernelImageReplicas,
});
type KernelImageConfig = Static<typeof KernelImageConfig>;

const KernelImages = Dictionary(KernelImageConfig, KernelImage);
type KernelImages = Static<typeof KernelImages>;

export const KernelConfig = RRecord({
  tag: RString,
  image: RString,
  defaultBaseImage: KernelImage,
  baseImages: KernelImages,
});
export type KernelConfig = Static<typeof KernelConfig>;

export const KernelSizes = Dictionary(KernelSizeConfig, KernelSize);
export type KernelSizes = Static<typeof KernelSizes>;

export const KernelManagerConfig = RRecord({
  backendHostname: RString,
  sslEnabled: Boolean,
  publicKernelSize: KernelSize,
  kernelSizes: KernelSizes,
  pythonKernel: KernelConfig,
  rKernel: KernelConfig,
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
}).withConstraint(({ kernelSizes, pythonKernel, rKernel }) => {
  // TypeORM wants you to return a string in a case of failure
  return (
    validateKernelConfig(rKernel, kernelSizes) ??
    validateKernelConfig(pythonKernel, kernelSizes) ??
    true
  );
});

export type KernelManagerConfig = Static<typeof KernelManagerConfig>;

function validateKernelConfig(
  kernelConfig: KernelConfig,
  kernelSizes: KernelSizes,
): string | undefined {
  if (!(kernelConfig.defaultBaseImage in kernelConfig.baseImages)) {
    return `Default base image ${kernelConfig.defaultBaseImage} not found in base images`;
  }
  const failures = typedObjectEntries(kernelConfig.baseImages)
    .map(([baseImage, baseImageConfig]) => {
      const imageReplicas = typedObjectKeys(baseImageConfig.replicas);
      if (imageReplicas.length !== Object.keys(kernelSizes).length) {
        return `Base image ${baseImage} does not specify replicas for all kernel sizes`;
      }
      imageReplicas.forEach((kernelSize) => {
        if (!(kernelSize in kernelSizes)) {
          return `Base image ${baseImage} specifies replicas for invalid kernel size ${kernelSize}`;
        }
      });
    })
    .filter(notEmpty);

  if (failures.length > 1) {
    return failures.join(".\n");
  }
}

interface BillingConfig {
  priceId: StripePriceId | null;
  attachToExistingSubscriptions: boolean;
  meterId: string;
}
export type ComputeProfileBillingConfig = Record<KernelSize, BillingConfig>;

// todo: whenever we have to update these prices, it probably makes more sense
// to query the user's subscription and create a dictionary of compute prices for said user.
// For any profiles they don't have a subscription item for, we should use whatever
// the configured price is for this stack.
export type ComputeProfilePrices = Partial<Record<KernelSize, string>>;
export const DEFAULT_COMPUTE_PROFILE_PRICES: ComputeProfilePrices = {
  "2xlarge": "$1.29/hr",
  "4xlarge": "$2.58/hr",
  v100: "$6.70/hr",
};
